import React, { useEffect, useState } from 'react'

// import logoIcon from '../assets/images/'

import '../assets/css/personal_profile.css'
import { useQuery, useMutation } from "@apollo/client"
import {
    COUNTRY_QUERY,
    UPDATE_USER_MUTATION,
    VALIDATE_USER_EMAIL_ID,
    USER_QUERY
} from "../components/graphql"

import Layout from '../components/layout'
import PhoneInput from "react-phone-number-input"
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import "react-phone-number-input/style.css"


import {
    TextField,
    MenuItem
} from "@material-ui/core"
import axios from 'axios'
import apiUrl from '../api/apiUrl'
import loginIcon from '../assets/images/sidelogo.svg'

import { Button } from 'antd'
import { CheckOutlined,CloseOutlined} from '@ant-design/icons';
import { Link,navigate } from "gatsby";



function EditProfile() {
    // const [id, setid] = useState(undefined)

    useEffect(() => {
        // setid(localStorage.getItem("userId"))
        getUserInfo()
    }, [])

    // const userValue = useQuery(USER_QUERY, {
    //     variables: { id: id }

    // })

    // useEffect(() => {
    //     if (userValue?.data?.user) {
    //         //setfirstName('')
    //         // setlastName(userValue?.data?.user?.lastName)
    //         // setDateOfBirth(userValue?.data?.user?.dateOfBirth)
    //         // setMobileNumber(userValue?.data?.user?.mobileNumber)
    //         // setGender(userValue?.data?.user?.Gender)
    //         // setcity(userValue?.data?.user?.cityName)
    //         // setname(userValue?.data?.user?.username)
    //         // setemail(userValue?.data?.user?.email)
    //         // setcurrentLocation(userValue?.data?.user?.currentLocation)
    //         // setcurrentState(userValue?.data?.user?.state)
    //         // setcurrentCountry(userValue?.data?.user?.countryName?.id)
    //     }

    // }, [userValue])


    // console.log('uservalue', userValue?.data?.user?.Gender)

    const [firstName, setfirstName] = useState()
    const [lastName, setlastName] = useState("")
    const [dateOfBirth, setDateOfBirth] = useState("")
    const [mobileNumber, setMobileNumber] = useState('')
    const [gender, setGender] = useState("")
    const [city, setcity] = useState("")
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [currentLocation, setcurrentLocation] = useState("")
    const [currentState, setcurrentState] = useState()
    const [currentCountry, setcurrentCountry] = useState("")

    const [nameError, setnameError] = useState('')
    const [emailError, setemailError] = useState('')


    const countryQuery = useQuery(COUNTRY_QUERY)
    const countryList = countryQuery?.data?.countries || []


    const [isLoading, setisLoading] = useState(false)
    const [notification, setNotification] = useState({})



    const getUserInfo = async () => {
        try {

            var response = await axios.get(`${apiUrl.updateUserProfile}${localStorage.getItem('userId')}`, {}, {
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                }
            })

            if (response?.data?.id) {
                setfirstName(response?.data?.firstName || 'eee')
                setlastName(response?.data?.lastName)
                setDateOfBirth(response?.data?.dateOfBirth)
                setMobileNumber(response?.data?.mobileNumber)
                setGender(response?.data?.Gender)
                setcity(response?.data?.cityName)
                // setname(response?.data?.username)
                setemail(response?.data?.email)
                setcurrentLocation(response?.data?.currentLocation)
                setcurrentState(response?.data?.state)
                setcurrentCountry(response?.data?.countryName?.id)

            }

            console.log('res', response.data)
        } catch (error) {
            console.log(error)
        }
    }



    const validation = async () => {
        // if (!name) { setnameError('Enter name'); return null }
        if (!email) { setemailError('Enter email'); return null }

        try {
            setisLoading(true)
            const requestHeader = {
                headers: {
                    Authorization: localStorage.getItem('token') ? `Bearer ${localStorage.getItem('token')}` : '',
                }
            }
            var response = await axios.post(apiUrl.updateProfile,
                {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    mobileNumber: mobileNumber,
                    Gender: gender,
                    cityName: city,
                    dateOfBirth: dateOfBirth,
                    currentLocation: currentLocation,
                    state: currentState,
                    countryName: currentCountry
                }, requestHeader

            )
            if(response.data){
                setNotification({error:false, message:'Profile data updated successfully'})
                setisLoading(false)
            }
        } catch (error) {
            setNotification({error:true, message:'failed to update , try again later'})
            setisLoading(false)
            console.log(error)

        }

    }


    return (
        <>
            <Layout setTitle="User Profile">

                <div class="row m-0" >
                    <div class="col-md-6 position-relative login-m-none" style={{ backgroundColor: '#1e69b5', height: '100vh' }}>
                        <img class="login-img" src={loginIcon} />
                    </div>


                    <div className="col-md-6 position-relative" >
                        <div className="edit-personal-profile-right text-center" >
                            {/* <img className="edit-personal-profile-logo" src={logoIcon} /> */}
                            <h6 style={{ color: "#1e69b5" }}>Personal Profile</h6>
                            <CloseOutlined onClick={(e)=>navigate("/user-profile")} style={{fontSize:'20px',position:'absolute',right:'50px',top:'0px'}} />
                            <div className="edit-personal-profile-underline"></div>
                        </div>

                        <div className="edit-personal-profile-edit mx-5 mt-5" >
                        <div className="row w-100">
                            <span className={ notification?.error ?'text-danger' :"text-success text-center"}>{notification?.message}</span>
                        </div>
                            <span className="fw-bold">Edit profile</span>
                            <div className="row mt-3">
                                {/* <div className="col-md-3">
                            <div className="edit-profile-avatar">
                            </div>
                        </div> */}

                                <div className="row">
                                    <div className="col">
                                        {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}
                                        <TextField
                                            className="w-100"
                                            margin="dense"
                                            id="firstname"
                                            label="First Name"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={firstName}
                                            onChange={e => {
                                                setfirstName(e.target.value)
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                    <div className="col">
                                        <TextField
                                            className="w-100"
                                            margin="dense"
                                            id="lastname"
                                            label="last Name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="text"
                                            value={lastName}
                                            onChange={e => {
                                                setlastName(e.target.value)
                                            }}
                                        >
                                        </TextField>
                                    </div>
                                </div>



                                <div className="row">
                                    {/* <div className="col">
                                        <TextField id="standard-basic"
                                            value={name}
                                            onChange={(e) => setname(e.target.value)}
                                            label="Username"
                                            variant="standard"
                                            className="w-100"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            helperText={nameError ? nameError : ""}
                                            error={nameError}
                                        />

                                    </div> */}

                                    <div className="col">
                                        <TextField id="standard-basic"
                                            value={email}
                                            onChange={(e) => setemail(e.target.value)}
                                            label="Email"
                                            variant="standard"
                                            className="w-100"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="email"
                                            helperText={emailError ? emailError : ""}
                                            error={emailError}
                                            fullWidth />
                                    </div>

                                </div>


                            </div>
                            <div className="row m-0 p-0">
                                <div className="col m-0 p-0">
                                    {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                                    <PhoneInput
                                        // countryCallingCodeEditable={false}
                                        international
                                        className="w-100 mt-4 pt-1"
                                        countryCallingCodeEditable={false}
                                        defaultCountry="FR"
                                        value={mobileNumber}
                                        placeholder="Contact Number"
                                        onChange={e => {
                                            setMobileNumber(e)
                                        }}
                                    >
                                    </PhoneInput>{''}

                                </div>
                                <div className="col">
                                    {/* <TextField id="standard-basic" label="dob" variant="standard" className="w-100 mt-3" /> */}

                                    <TextField
                                        className="w-100"
                                        margin="dense"
                                        id="date-of-birth"
                                        label="Date of Birth"
                                        type="date"
                                        // defaultValue={"2017-05-24"}
                                        placeholder=""
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={dateOfBirth}
                                        onChange={e => {
                                            setDateOfBirth(e.target.value)
                                        }}

                                    />

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        margin="dense"
                                        id="marks-value"
                                        label="Current location"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="text"
                                        value={currentLocation}
                                        onChange={e => {
                                            setcurrentLocation(e.target.value)
                                        }}

                                    />

                                </div>
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        select
                                        margin="dense"
                                        id="gender"
                                        label="Gender"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="text"
                                        value={gender}
                                        onChange={e => {
                                            setGender(e.target.value)
                                        }}

                                    >
                                        <MenuItem value={"Male"}>
                                            Male
                                        </MenuItem>
                                        <MenuItem value={"Female"} >
                                            Female
                                        </MenuItem>
                                        <MenuItem value={"Other"}>
                                            Other
                                        </MenuItem>
                                    </TextField>

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}
                                    <TextField
                                        className="w-100"
                                        margin="dense"
                                        id="city"
                                        label="Current City"
                                        type="text"
                                        value={city}
                                        onChange={e => {
                                            setcity(e.target.value)
                                        }}


                                    >

                                    </TextField>

                                </div>
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        margin="dense"
                                        id="state"
                                        label="State"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={currentState}
                                        onChange={e => {
                                            setcurrentState(e.target.value)
                                        }}

                                    >
                                    </TextField>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <TextField
                                        className="w-100"
                                        select
                                        margin="dense"
                                        id="country"
                                        label="Country"
                                        type="text"
                                        value={currentCountry}
                                        onChange={e => {
                                            setcurrentCountry(e.target.value)
                                        }}
                                    >
                                        {countryList.map((item, index) => <MenuItem value={item?.id} key={index}>
                                            {item?.countryName}
                                        </MenuItem>)}

                                    </TextField>

                                </div>
                                <div className="col">
                                    {/* <TextField id="standard-basic" label="Phone" variant="standard" className="w-100 mt-3" /> */}

                                </div>
                            </div>



                            <div className="row w-100">
                                <div className="col mt-3 p-1">
                                    <div className="cta-secondary">
                                    <Link to="/user-profile" className="cancel-btn me-2" size={'large'} color="primary">
                                        Cancel
                                    </Link>
                                    </div>
                                    <div className="cta-primary">
                                    <Button type="primary"  onClick={validation} loading={isLoading} size={'large'} icon={<CheckOutlined />}>
                                        Save
                                    </Button>
                                    </div>

                                    {/* <Button
                                        onClick={(e) => { validation() }}
                                        className="save-btn"
                                        color="primary"
                                    >
                                        Save
                                    </Button> */}
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </Layout>

        </>)
}

export default React.memo(EditProfile)
